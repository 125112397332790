import React from "react";
import Footer from "./Footer";
import styled from "styled-components";
import { Section, SectionHeading } from "./SharedStyles";

const ServiceList = styled.ul`
  padding: 0 2.5rem;
  z-index: 10;
  position: relative;
  list-style: none;

  li {
    padding: 0;

    &:before {
      content: "\2022";
      color: ${(props) => props.theme.cyan};
    }
  }
`;

const LinkList = styled.ul`
  list-style: none;
  padding-left: 1rem;

  li {
    padding-left: 3rem;
    text-indent: -3rem;

    &:before {
      content: "★";
      color: ${(props) => props.theme.cyan};
      margin-right: 0.5em;
      font-size: 1.2em;
    }
  }

  @media (max-width: ${(props) => props.theme.midBreak}) {
    padding-left: 0rem;
  }
`;

const Services = (props) => {
  return (
    <Section contentVisible={props.contentVisible}>
      <SectionHeading>Our services</SectionHeading>
      <p>
        Every project is unique, so we would alway prefer to design our research
        to meet your specific needs and circumstances.
      </p>
      <p>
        But to give you a better idea of what we do, here are a few examples of
        the kinds of services that we offer:
      </p>
      <LinkList>
        <li>Formative audience insight research</li>
        <li>In-depth project discovery research</li>
        <li>Research for iterative product development</li>
        <li>Stand-alone user experience and usability testing</li>
        <li>Survey design and analysis</li>
        <li>Research and analysis training and mentoring</li>
        <li>Research processes and knowledge management</li>
        <li>Assistance with hiring user researchers</li>
      </LinkList>
      <p>
        We draw on a range of research and analysis methods, qualitative and
        quantitative, to find the most effective tools for the job.
      </p>
      <p>
        If you would like to discuss a project that you have in mind, please
        send us an email.
      </p>
      <Footer />
    </Section>
  );
};

const images = require.context("./img", true);

export default Services;
