import React, { Component } from "react";
import Footer from "./Footer";
import styled from "styled-components";
import { Section, SectionHeading, BaseButton } from "./SharedStyles";

const ButtonSpacer = styled.div`
  margin-bottom: ${(props) => props.theme.standardSpacing};
  margin-top: ${(props) => props.theme.standardSpacing};
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  transform: ${(props) => (props.clicked ? "translateX(1rem)" : "")};
  transition: transform 0.2s;

  &:hover {
    transform: translateX(1rem);
  }

  @media (hover: none), (-moz-touch-enabled: 1), (pointer: coarse) {
    transform: none;

    &:hover {
      transform: none;
    }
  }
`;

const Button = styled(BaseButton)`
  padding: 1rem;
  border: 2px solid ${(props) => props.theme.brightGreen};
  background-color: ${(props) =>
    props.clicked ? props.theme.brightGreen : props.theme.paleGreen};
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
  display: inline-block;
  color: #222222;
  position: relative;
  line-height: 2.4rem;
  transform: ${(props) => (props.clicked ? "scale(1.025)" : "")};
  transition: transform 0.3s cubic-bezier(0.1, 1, 0.4, 3);

  &:hover {
    cursor: pointer;
  }
`;

const SecondaryButtonText = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  padding: 1rem;
  color: white;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  vertical-align: bottom;

  visibility: ${(props) => (props.textVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.textVisible ? 1 : 0)};
  overflow: hidden;
  transition: opacity 0.5s;
`;

const ButtonText = styled.span`
  visibility: ${(props) => (props.textVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.textVisible ? 1 : 0)};
  overflow: hidden;
  transition: opacity 0.5s;
`;

const Garble = styled.span`
  visibility: ${(props) => (props.garble === "frag" ? "hidden" : "")};
  position: ${(props) => (props.garble === "frag" ? "absolute" : "")};
  top: ${(props) => (props.garble === "frag" ? 0 : "")};
  left: ${(props) => (props.garble === "frag" ? 0 : "")};
  width: ${(props) => (props.garble === "frag" ? 0 : "")};
  height: ${(props) => (props.garble === "frag" ? 0 : "")};
  unicode-bidi: ${(props) => (props.garble === "back" ? "bidi-override" : "")};
  direction: ${(props) => (props.garble === "back" ? "rtl" : "")};
  color: ${(props) => (props.garble === "fold" ? props.theme.paleGreen : "")};
  margin-right: ${(props) => (props.garble === "fold" ? "-0.4rem" : "")};

  &::before {
    content: ${(props) => (props.garble === "prep" ? '"@"' : "")};
  }
`;

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0.1;
  top: 0;
  left: 0;
  border: none;
  color: white;

  &:focus {
    outline: 0;
  }

  &::selection {
    color: white;
    background: white;
  }
`;

class Contact extends Component {
  state = {
    copyButtonActive: false,
    mailButtonActive: false,
    emailAddress: "U29ycnksIHRoZXJlIHdhcyBhbiBlcnJvcg==",
  };

  componentDidMount() {
    setTimeout(() => {
      this.updateEmail();
    }, 3000);
  }

  updateEmail = () =>
    this.setState({ emailAddress: "am9zZXBoQGtheXByb2R1Y3RzLmNvLnVr" });

  getEmail = () => atob(this.state.emailAddress);

  handleCopyEmailClick = (errorHandler) => {
    const textArea = document.querySelector("#email");
    if (navigator.userAgent.match(/ipad|iphone/i)) {
      const range = document.createRange();
      range.selectNodeContents(textArea);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
    const copied = document.execCommand("copy");
    if (copied === true) {
      this.setState({
        copyButtonActive: true,
      });
      setTimeout(() => {
        this.setState({
          copyButtonActive: false,
        });
      }, 2000);
    } else {
      errorHandler(
        "Oh no - the copy button didn't work!",
        "Sorry about that. Please let me know, so I can try to fix it."
      );
    }
  };

  handleMailtoClick = () => {
    window.location.href = "mailto:" + this.getEmail();
    this.setState({
      mailButtonActive: true,
    });
    setTimeout(() => {
      this.setState({
        mailButtonActive: false,
      });
    }, 2000);
  };

  addEmailAddress = (text) => {
    return (
      <React.Fragment>
        {text}
        <span>jos</span>
        <Garble garble="frag">@jo</Garble>
        <Garble garble="frag">ind.com</Garble>
        <Garble garble="back">hpe</Garble>
        <Garble garble="fold">i</Garble>
        <Garble garble="prep">kay</Garble>
        <Garble garble="frag">efk.com</Garble>
        <Garble garble="back">dorp</Garble>
        <Garble garble="fold">i</Garble>
        <span>ucts</span>
        <Garble garble="frag">@kay.com</Garble>
        <Garble garble="back">.oc.</Garble>
        <span>uk</span>
      </React.Fragment>
    );
  };

  render() {
    return (
      <Section
        contentVisible={this.props.contentVisible}
        onMouseOver={this.updateEmail}
      >
        <SectionHeading>Say hello</SectionHeading>
        <p>
          If you have any questions or any ideas for how Kay Products can help
          you, please get in touch.
        </p>
        <ActionButton
          active={this.state.copyButtonActive}
          clickAction={this.handleCopyEmailClick}
          handleError={this.props.handleError}
          defaultText={this.addEmailAddress("Copy: ")}
          defaultEmoji="📋"
          defaultEmojiLabel="clipboard emoji"
          clickText="Copied!"
          clickEmoji="👍🏻"
          clickEmojiLabel="thumbs-up emoji"
          ariaLabel="Copy Joseph's email address to your clipboard"
        />
        <HiddenInput
          id="email"
          value={this.getEmail()}
          readOnly
          tabIndex="-1"
          aria-hidden="true"
        />
        <ActionButton
          active={this.state.mailButtonActive}
          clickAction={this.handleMailtoClick}
          defaultText={this.addEmailAddress("Mailto: ")}
          defaultEmoji="✉️"
          defaultEmojiLabel="clipboard emoji"
          clickText="Opening..."
          ariaLabel="Send email to Joseph using default program"
        />
        <Footer />
      </Section>
    );
  }
}

const ActionButton = (props) => (
  <ButtonSpacer>
    <ButtonWrapper clicked={props.active ? true : false}>
      <Button
        clicked={props.active ? true : false}
        onClick={() => props.clickAction(props.handleError)}
        aria-label={props.ariaLabel}
      >
        <ButtonText
          textVisible={props.active ? false : true}
          aria-hidden="true"
        >
          {props.defaultText}
          <span role="img" aria-label={props.defaultEmojiLabel}>
            {props.defaultEmoji !== undefined ? " " + props.defaultEmoji : ""}
          </span>
        </ButtonText>
        <SecondaryButtonText textVisible={props.active ? true : false}>
          {props.clickText}
          <span role="img" aria-label={props.clickEmojiLabel}>
            {props.clickEmoji !== undefined ? " " + props.clickEmoji : ""}
          </span>
        </SecondaryButtonText>
      </Button>
    </ButtonWrapper>
  </ButtonSpacer>
);

export default Contact;
