import React from "react";
import styled from "styled-components";
import { BaseButton } from "./SharedStyles";

const NavbarHolder = styled.div`
  width: 100%;
  min-height: 7.5rem;
  position: relative;
  transition: ${(props) => (props.mainNav ? "height 0.1s 0.2s" : "")};

  @media (max-width: ${(props) => props.theme.midBreak}) {
    height: ${(props) => (props.mainNav ? "14rem" : "7.5rem")};
  }
`;

const Nav = styled.nav`
  background-color: white;
  z-index: 100;
  height: ${(props) => (props.stuck ? "7.5rem" : "")};
  position: ${(props) => (props.stuck ? "fixed" : "absolute")};
  top: 0;
  width: 64rem;
  margin-left: -2rem;

  @media (max-width: ${(props) => props.theme.midBreak}) {
    width: 100%;
    margin: 0;
    overflow-y: ${(props) => (props.stuck ? "hidden" : "")};
    left: ${(props) => (props.stuck ? 0 : "")};
    box-shadow: ${(props) =>
      props.stuck ? "0 0.4rem 0.8rem rgba(220, 220, 220, 0.6)" : ""};
  }
`;

const NavButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  padding-left: 2rem;
  box-sizing: border-box;
  box-shadow: 0 0.8rem 0.8rem -0.5rem rgba(220, 220, 220, ${(props) => (props.stuck ? 0.6 : 0)});
  transition: box-shadow 1s,
    visibility ${(props) => (props.activeNav ? "1s" : "")} 0.2s,
    opacity ${(props) => (props.activeNav ? "1s" : "")} 0.2s, max-height 0s 0.2s;
  visibility: ${(props) => (props.activeNav ? "visible" : "hidden")};
  opacity: ${(props) => (props.activeNav ? 1 : 0)};
  max-height: ${(props) => (props.activeNav ? "1000rem" : 0)};
  overflow: ${(props) => (props.activeNav ? "" : "hidden")};

  @media (max-width: ${(props) => props.theme.midBreak}) {
    box-sizing: ${(props) => (props.stuck ? "border-box" : "content-box")};
    padding: ${(props) => (props.stuck ? "0 0 2rem 2rem" : 0)};
    overflow-x: ${(props) => (props.stuck ? "scroll" : "")};
    white-space: ${(props) => (props.stuck ? "nowrap" : "")};
    margin-bottom: ${(props) => (props.stuck ? "-2rem" : "")};
  }
`;

const Button = styled(BaseButton)`
  color: ${(props) => (props.activeButton ? "white" : "inherit")};
  background-color: ${(props) =>
    props.activeButton ? props.theme.darkBlue : "none"};
  padding: 1rem;
  border: 2px solid
    ${(props) => (props.activeButton ? props.theme.darkBlue : props.theme.cyan)};
  margin: 1rem 1rem 1rem 0;
  border-radius: 0.5rem;
  display: inline-block;
  line-height: 3rem;
  transition: background-color 0.2s, color 0.2s;

  span.back-arrow::before {
    content: "← ";
  }

  &:hover {
    cursor: pointer;
    #background-color: ${(props) =>
      props.activeButton ? props.theme.darkBlue : "#e6feff"};
    #color: white;
    border-color: ${(props) => props.theme.darkBlue};
    transition: border-color 0.2s;
  }

  @media (max-width: ${(props) => props.theme.midBreak}) {
    &:last-child {
      margin-right: 2rem;
    }
  }
`;

const Navbar = (props) => {
  return (
    <NavbarHolder
      mainNav={!props.articleActive}
      ref={props.navbarRef}
      id="navbar-holder"
    >
      <Nav stuck={props.navStuck}>
        <NavButtonWrapper
          stuck={props.navStuck}
          activeNav={!props.articleActive}
        >
          <NavButton
            name="Services"
            onButtonClick={props.handleNavChange}
            activeContent={props.activeContent}
          />
          <NavButton
            name="Contact"
            onButtonClick={props.handleNavChange}
            activeContent={props.activeContent}
          />
        </NavButtonWrapper>
        <NavButtonWrapper
          stuck={props.navStuck}
          activeNav={props.articleActive}
        >
          <NavButton
            name={props.activeContent}
            onButtonClick={props.handleNavChange}
            activeContent={props.activeContent}
            articleActive={props.articleActive}
            prepend="back-arrow"
          />
          <Button key="case-study" activeButton={true} aria-current="page">
            Case Study
          </Button>
        </NavButtonWrapper>
      </Nav>
    </NavbarHolder>
  );
};

const NavButton = (props) => {
  let contents;
  if (props.prepend === undefined) {
    contents = props.name;
  } else {
    contents = <span className={props.prepend}>{props.name}</span>;
  }
  return (
    <Button
      key={props.name}
      activeButton={
        !props.articleActive && props.activeContent === props.name
          ? true
          : false
      }
      aria-current={
        !props.articleActive && props.activeContent === props.name ? "page" : ""
      }
      onClick={() => props.onButtonClick(props.name)}
    >
      {contents}
    </Button>
  );
};

export default Navbar;
